import React, { useEffect }  from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout2 from '../../components/layout2'

const ScheduleArchivePage = ({ data }) => {
  useEffect(() => {
    console.log('useEffect');
    var element = document.getElementById('home');
//    element.style.height = 'auto';
    element.style['min-height'] = '0px';
  },[]);

  return (
    <Layout2 pageTitle="スケジュールアーカイブ">
      <section id='work'>
        <div className='row sarchive'>
          <div className='two columns header-col'>
            <h1>
              <span>Archives</span>
            </h1>
          </div>
          <div className='ten columns main-col'>
      <ul>
      {
        data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <h2>
              <Link to={`/sarchives/${node.slug}`}>
                {node.frontmatter.title}
              </Link>
            </h2>
		{/* <p>Posted: {node.frontmatter.date}</p> */}
          </article>
        ))
      }
      </ul>
      </div>
      </div>
          <div className="row schedule-menu-line"><p className="sched-archive"><a href="/#work">&lt;&lt;戻る</a></p></div>
      </section>
    </Layout2>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: {frontmatter: {type: {eq: "archive"}}}
      sort: {fields: frontmatter___date, order: ASC}
      ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`
export default ScheduleArchivePage
